const DetailsStatusEnum = Object.freeze({
    TEMPERATURE: 1,
    HUMIDITY: 2,
    PRESSURE: 3,
    RAIN: 4,
    WIND_SPEED: 5,
    UV: 6,
    DEWPOINT: 7
  });
  
  export default DetailsStatusEnum;