let station = '';
export default function ForecastTable(symbols, stationName) {
    station = stationName;
    let forecast = symbols.nn_shortText._text;
    let precip = symbols.wwsyn_shortText._text;
    let spec = symbols.rr_icon._text;

    let minTemp = symbols.tnsyn_degreesC._text;
    let maxTemp = symbols.txsyn_degreesC._text

    if (forecast === "pretežno oblačno" && precip === "sneženje" && spec === "light") {
        symbols = "d212";
    }
    else if (forecast === "pretežno oblačno" && precip === "dež s snegom" && spec === "mod") {
        if (CheckTempLevel(minTemp, maxTemp)) {
            symbols = "d322";
        }
        else {
            symbols = "d321";
        }
    }
    else if (forecast === "pretežno oblačno" && precip === "dež s snegom" && spec === "light") {
        if (CheckTempLevel(minTemp, maxTemp)) {
            symbols = "d322";
        }
        else {
            symbols = "d321";
        }
    }
    else if (forecast === "pretežno oblačno" && precip === "sneženje") {
        symbols = "d322";
    }
    else if (forecast === "pretežno oblačno" && precip === undefined) {
        symbols = "d300";
    }
    else if (forecast === "pretežno oblačno" && precip === "snežne plohe") {
        symbols = "d312";
    }
    else if (forecast === "pretežno oblačno" && precip === "plohe") {

        if (CheckTempLevel(minTemp, maxTemp)) {
            symbols = "d321";
        }
        else {
            symbols = "d320";
        }
    }
    else if (forecast === "pretežno oblačno" && precip === "dež" && spec === "mod") {
        if (CheckTempLevel(minTemp, maxTemp)) {
            symbols = "d321";
        }
        else {
            symbols = "d320";
        }
    }
    else if (forecast === "pretežno oblačno" && precip === "dež" && spec === "light") {

        if (CheckTempLevel(minTemp, maxTemp)) {
            symbols = "d311";
        }
        else {
            symbols = "d310";
        }

    }
    else if (forecast === "jasno" && precip === undefined) {
        symbols = "d000";
    }
    else if (forecast === "pretežno jasno" && precip === "sneženje") {
        symbols = "d222";
    }
    else if (forecast === "pretežno jasno") {
        symbols = "d100";
    }
    else if (forecast === "delno oblačno" && precip === undefined) {
        symbols = "d200";
    }
    else if (forecast === "delno oblačno" && precip === "plohe dežja in snega") {

        if (CheckTempLevel(minTemp, maxTemp)) {
            symbols = "d222"
        }
        else {
            symbols = "d221";
        }

    }
    else if (forecast === "delno oblačno" && precip === "dež s snegom" && spec == "mod") {

        if (CheckTempLevel(minTemp, maxTemp)) {
            symbols = "d222"
        }
        else {
            symbols = "d221";
        }

    }
    else if (forecast === "delno oblačno" && precip === "plohe") {

        if (CheckTempLevel(minTemp, maxTemp)) {
            symbols = "d221"
        }
        else {
            symbols = "d220";
        }
    }
    else if (forecast === "delno oblačno" && precip === "dež") {

        if (CheckTempLevel(minTemp, maxTemp)) {
            symbols = "d221"
        }
        else {
            symbols = "d220"
        }

    }
    else if (forecast === "delno oblačno" && precip === "dež s snegom" && spec === "light") {
        if (CheckTempLevel(minTemp, maxTemp)) {
            symbols = "d212"
        }
        else {
            symbols = "d211"
        }
    }
    else if (forecast === "zmerno oblačno" && precip === undefined) {
        symbols = "d200";
    }
    else if (forecast === "oblačno" && precip === undefined) {
        symbols = "d400";
    }
    else if (forecast === "oblačno" && precip === "rahlo sneženje") {
        symbols = "d412";
    }
    else if (forecast === "oblačno" && precip === "dež s snegom") {

        if (CheckTempLevel(minTemp, maxTemp)) {
            symbols = "d422";
        }
        else {
            symbols = "d421";
        }
    }
    else if (forecast === "oblačno" && precip === "plohe dežja in snega" && spec === "light") {
        if (CheckTempLevel(minTemp, maxTemp)) {
            symbols = "d412";
        }
        else {
            symbols = "d411";
        }
    }
    else if (forecast === "oblačno" && precip === "snežne plohe" && spec === "light") {
        symbols = "d412";
    }
    else if (forecast === "oblačno" && precip === "sneženje" && spec === "mod") {
        symbols = "d422";
    }
    else if (forecast === "oblačno" && precip === "sneženje" && spec === "light") {
        symbols = "d412"
    }
    else if (forecast === "oblačno" && precip === "dež" && spec === "mod") {

        if (CheckTempLevel(minTemp, maxTemp)) {
            symbols = "d421";
        }
        else {
            symbols = "d420";
        }

    }
    else if (forecast === "oblačno" && precip === "plohe" && spec === "mod") {
        if (CheckTempLevel(minTemp, maxTemp)) {
            symbols = "d421";
        }
        else {
            symbols = "d420";
        }

    }
    else if (forecast === "oblačno" && precip === "dež" && spec === "light") {

        if (CheckTempLevel(minTemp, maxTemp)) {
            symbols = "d411";
        }
        else {
            symbols = "d410";
        }

    }
    else if (forecast === "oblačno" && precip === "plohe" && spec === "light") {

        if (CheckTempLevel(minTemp, maxTemp)) {
            symbols = "d411";
        }
        else {
            symbols = "d410";
        }

    }
    return symbols;
}

function CheckTempLevel(min, max) {
    if(min + max <= 5 && station === 'loski-potok') {
        return true;
    }
    else {
        return false;
    }
}