<template>
  <div class="table-responsive">
    <table
      class="table table-hover mb-1 mt-1"
      v-for="item in data"
      :key="item.id"
      data-bs-toggle="modal"
      data-bs-target="#myModal"
      @click="ShowModal(item)"
    >
      <tbody>
        <tr class="first">
          <td scope="row" title="Open Preview" class="p-0">
            <div class="container-fluid p-0">
              <div class="row align-items-start m-0 mt-2">
                <div class="col-12 col-md-4">
                  <img class="img img-responsive news-img shadow mb-2" :src="item.imgUrl" />
                </div>
                <div class="col-12 col-md-6">
                  <span class="title">
                    <b>{{ item.title }}</b>
                    <br />
                    <p id="date-mobile">{{ item.day }}. {{item.month }}</p>
                  </span>
                  <span class="content">{{ item.content.substring(0, item.content.indexOf('.')) }}.</span>
                </div>
                <div class="col-12 col-md-2 text-center">
                  <div id="date" class="mt-5">
                    {{ item.day }} <br />
                    {{ item.month }}
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <NewsModal :item="filtered" />
  </div>
</template>

<script>
import NewsModal from "./NewsModal.vue";
import News from "../../../Data/News/News";

export default {
  name: "NewsWidget",
  data() {
    return {
      data: News,
      filtered: {},
    };
  },
  components: {
    NewsModal,
  },
  methods: {
    ShowModal(item) {
      this.filtered = item;
    },
  },
};
</script>

<style scoped>
#date-mobile {
    display: none;
}

.borderless td,
.borderless th {
  border: none;
  font-size: 13px;
}

.content {
  font-size: 0.70rem;
  cursor: pointer;
}

#date {
  font-size: 0.85rem;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.title {
  font-size: 0.95rem;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.first {
  cursor: pointer;
}

.more {
  border-radius: 5px;
}

@media (min-width: 1920px) {
  .news-img {
    width: 200px;
    height: 135px;
  }
}


@media (max-width: 1920px) {
  .news-img {
    width: 190px;
    height: 135px;
  }
}

@media (max-width: 1800px) {
  .news-img {
    width: 180px;
    height: 135px;
  }
}

@media (max-width: 1750px) {
  .news-img {
    width: 170px;
    height: 135px;
  }
}

@media (max-width: 1700px) {
  .news-img {
    width: 160px;
    height: 135px;
  }
}

@media (max-width: 1600px) {
  .news-img {
    width: 150px;
    height: 135px;
  }
}

@media (max-width: 1520px) {
  .news-img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 1277px) {
  .news-img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 1237px) {
  table th {
    font-size:8px;  
  }
}

@media (max-width: 1200px) {
.news-img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 888px) {
  .news-img {
    width: 100%;
    height: auto;
  }
  #date {
    display: none;
  }
  #date-mobile {
    display: block;
    font-size: 12px;
  }
}

@media (min-width: 751px) {
  #date {
    align-items: flex-start;
  }
}
</style>