<template>
  <div
    class="modal fade"
    id="myModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="container-fluid">
          <div class="modal-header">
            <div class="row">
              <div class="col-12 col-md-12">
                <h5 class="modal-title" id="exampleModalLabel">
                  <div class="text-uppercase fw-bold">{{ item.title }}</div>
                </h5>
              </div>
              <div class="col-12 col-md-12 text-center">
                <img
                  class="img img-fluid mt-2"
                  :src="item.modalImgUrl != null ? item.modalImgUrl : item.imgUrl"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12">
              <div class="modal-body">
                <div v-for="(paragraph, i) in CreateParagraphs(item.content)" :key="paragraph">
                  <div v-if="i == 0">
                    {{ paragraph }}
                  </div>
                  <div v-else>
                    <br />{{ paragraph }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Zapri
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateParagraphs from '../../../Data/News/Editor';

export default {
  name: "NewsModal",
  data() {
    return {
      count: 0
    };
  },
  props: {
    item: Object,
  },
  methods: {
    CreateParagraphs
  }
};
</script>