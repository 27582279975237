<template>
  <div class="tablet">
    <a href="#" data-bs-toggle="modal" data-bs-target="#bannerformmodal">
      <img
        class="img img-fluid"
        src="https://meteo.arso.gov.si/uploads/probase/www/observ/radar/si0-rm.gif"
        alt="Radar ARSO"
      />
    </a>
    <RadarModal
      url="https://www.meteo.si/uploads/probase/www/observ/radar/si0-rm-anim.gif"
    />
  </div>
</template>

<script>
import RadarModal from "./RadarModal.vue";

export default {
  name: "RadarWidget",
  data() {
    return {
      webcamUrl: "",
    };
  },
  components: {
    RadarModal,
  },
  mounted() {
    /*
    var config = {
      method: "get",
      url: "https://api.windy.com/api/webcams/v2/list/webcam=1619364727?show=webcams:player",
      headers: {
        "x-windy-key": process.env.VUE_APP_WINDAPIKEY,
      },
    };

    axios(config)
      .then((response) => {
        this.webcamUrl = response.data.result.webcams[0].player.day.embed;
      })
      .catch((error) => {
        console.log(error);
      });
      */
  },
};
</script>

<style scoped>
@media (max-width: 1200px) {
  .tablet {
    text-align: center;
  }
}
</style>
