<template>
  <div class="text-center">
    <table class="table borderless table-hover" v-if="stationData.length > 0">
      <thead>
        <tr>
          <th scope="col">Postaja</th>
          <th scope="col">Temperatura</th>
          <th scope="col">Vlažnost</th>
          <th scope="col"><span v-if="stationData.length > 0"><span v-if="$route.params.stationName != 'sodrazica'">Zadnja osvežitev</span><span v-else> <span id="desktop">Vetrovne razmere</span><span id="mobile">Veter</span> </span></span></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">
            <fa icon="satellite-dish" id="station-icon" class="style-icon"></fa>
          </th>
          <td>
            <fa
              icon="thermometer-half"
              id="thermometer-icon"
              class="style-icon"
            ></fa>
          </td>
          <td><fa icon="tint" id="hygrometer-icon" class="style-icon"></fa></td>
          <td><fa :icon="icon" id="time-icon" class="style-icon"></fa></td>
        </tr>
        <tr
          v-for="station in stationData"
          :key="station.id"
          @click="GetDetails(station)"
          id="pointer"
        >
          <th scope="row">{{ station.name }}</th>
          <td v-if="station.isLate == false"><span v-if="station.hum !== undefined || station.temp !== null">{{ station.temp }}</span><span v-else>---</span> °C</td>
          <td v-if="station.isLate == false"><span v-if="station.hum !== undefined || station.hum != null">{{ station.hum }}</span><span v-else>---</span> %</td>
          <td v-if="station.isLate == false"><span v-if="(station.temp !== null || station.temp !== null) && $route.params.stationName != 'sodrazica'">{{ station.date }}</span><span v-else-if="$route.params.stationName == 'sodrazica'">{{station.dir}} {{ station.wind }} km/h</span><span v-else>---</span></td>
          <!--
          <td v-if="station.isLate"><span v-if="forecast.hum !== undefined || forecast.temp !== null">{{ forecast.temp }}</span><span v-else>---</span> °C</td>
          <td v-if="station.isLate"><span v-if="forecast.hum !== undefined || forecast.hum != null">{{ forecast.hum }}</span><span v-else>---</span> %</td>
          <td v-if="station.isLate"><span v-if="forecast.temp !== undefined || forecast.hum !== undefined">{{ moment(forecast.date).format('DD. MM. YYYY HH:mm') }}</span><span v-else>---</span></td>
          -->
        </tr>
      </tbody>
    </table>
    <div :class="spinnerContainerClass" v-else>
      <div class="row">
        <div class="col-12 col-md-12 text-center align-self-center">
             <div class="spinner-border text-primary" role="status" ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: "StationWidget",
  data() {
    return {
      params: null,
      forecast: null,
      error: null,
      loading: false,
      icon: 'clock',
      spinnerContainerClass: 'container-fluid p-md-5 mt-md-5 p-sm-0 mt-sm-0'
    };
  },
  methods: {
    moment,
    GetDetails(station) {
      if (this.$route.params.stationName != "test") {
      this.$router.push({
        name: "Details",
        params: {
          stationName: this.params.stationName,
          stationId: station.id,
          stationRange: "daily",
        },
      });
      }
    },
    getData() {
      /*
      axios(Lisca.Config())
        .then((response) => {
          Lisca.ConvertToJson(response.data);
          this.forecast = Lisca.OrganizeData();
        })
        .catch(function (error) {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
        */
    },
  },
  mounted() {
    this.params = this.$router.currentRoute.value.params;
    if(this.params.stationName == "sodrazica") {
      this.icon = "wind";
    }
    this.getData();
  },
  watch: {
    $route() {
      this.params = this.$router.currentRoute.value.params;
    },
  },
  props: {
    stationData: Array,
  },
};
</script>

<style scoped>
.table tbody {
  font-size: 14.5px;
}

#pointer {
  cursor: pointer;
}

.style-icon {
  font-size: 35px;
}
#station-icon {
  color: rgb(13, 94, 73);
}
#thermometer-icon {
  color: rgb(206, 21, 21);
}
#hygrometer-icon {
  color: rgb(73, 71, 196);
}
#time-icon {
  color: rgb(10, 9, 49);
}

.borderless td,
.borderless th {
  border: none;
}

.style-icon-sun {
  font-size: 20px;
}

#mobile {
  display: none;
}

@media (max-width: 1140px) {
  .borderless td,
  .borderless th {
    font-size: 12px;
  }
}

@media (max-width: 576px) {
  .borderless td,
  .borderless th {
    font-size: 12px;
  }

  #mobile {
    display: block;
  }

  #desktop {
    display: none;
  }
  
}

@media (max-width: 354px) {
  .borderless td,
  .borderless th {
    font-size: 10px;
  }


}

@media (max-width: 318px) {
  .borderless td,
  .borderless th {
    font-size: 8px;
  }
}
</style>