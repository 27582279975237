function CreateParagraphs(content) {
    let paragraphs = [];
    let text = '';
    if(content !== undefined) {
        for (let i = 0; i < content.length; i++) {
            const element = content[i];
            if (element == '&') {
                paragraphs.push(text.trim())
                text = '';
            }
            else {
                text = text + element;
            }
        }
    }
    return paragraphs;
}

export default CreateParagraphs;