<template>
  <div>
    <highcharts class="chart" :options="chartOptions"></highcharts>
    <div :class="recordsClass">
      <table class="table table-borderless text-center">
      <tbody>
        <tr>
          <td v-for="item in GetRecordsData" :key="item">MAKSIMUM: <span class="fw-bold">{{item.max}} °C</span></td>
        </tr>
        <tr>
          <td v-for="item in GetRecordsData" :key="item">MINIMUM: <span class="fw-bold">{{item.min}} °C</span></td>
        </tr>
      </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChartWidget",
  data() {
    return {
      recordsClass: "table-responsive"
    };
  },
  mounted() {
    if (this.$route.params.stationName == "sodrazica") {
      this.recordsClass = "d-none";
    }
  },
  methods: {},
  props: {
    chartOptions: Object,
    GetRecordsData: Object,
  },
};
</script>

<style scoped>
table tbody tr td {
  font-size: 12px;;
}
</style>