<template>
  <div class="container mt-4">
    <div class="row">
      <div class="col-12 col-xl-12">
        <Widget
          :widgetStatusEnum="WidgetStatusEnum.SLIDESHOW"
          widgetTitle=""
          widgetIcon="images"
          :showWidgetTitle="false"
          :GetSliderImageData="sliderData"
        />
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 col-xl-6 col-lg-6 widget">
        <Widget
          :widgetStatusEnum="WidgetStatusEnum.STATION"
          :widgetTitle="GetSodrazicaTitleDate"
          widgetIcon="star"
          :showWidgetTitle="true"
          :GetTableData="GetTableData"
        />
      </div>
      <div class="col-12 col-xl-6 col-lg-6">
        <Widget
          :widgetStatusEnum="WidgetStatusEnum.FORECAST"
          widgetTitle="Slikovna vremenska napoved"
          widgetIcon="bookmark"
          :showWidgetTitle="true"
        />
      </div>
    </div>
    <div class="row mt-4 d-none d-sm-flex">
      <div class="col-12 col-xl-6 col-lg-6 widget">
        <Widget
          :widgetStatusEnum="WidgetStatusEnum.CHART"
          widgetTitle="Graf gibanja temperature"
          widgetIcon="chart-line"
          :GetChartOptions="GetChartOptions"
          :GetMaxStatsData="GetRecordsDayData"
          :showWidgetTitle="true"
        />
      </div>
      <div class="col-12 col-lg-6 col-xl-6 d-sm-none d-md-flex">
        <Widget
          :widgetStatusEnum="WidgetStatusEnum.SODRAZICA"
          widgetIcon="donate"
          :widgetTitle="cameraTitle"
          :showWidgetTitle="true"
        />
      </div>
    </div>
    <div class="row mt-4 d-sm-flex d-md-none">
      <div class="col-12 col-lg-6 col-xl-6 widget">
        <Widget
          :widgetStatusEnum="WidgetStatusEnum.SPONSOR"
          widgetIcon="donate"
          :widgetTitle="cameraTitle"
          :showWidgetTitle="true"
        />
      </div>
      <div class="col-12 col-xl-6 col-lg-6">
        <Widget
          :widgetStatusEnum="WidgetStatusEnum.CHART"
          widgetTitle="Graf gibanja temperature"
          widgetIcon="chart-line"
          :GetChartOptions="GetChartOptions"
          :GetMaxStatsData="GetRecordsDayData"
          :showWidgetTitle="true"
        />
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 col-xl-6 col-lg-6 widget">
        <Widget
          :widgetStatusEnum="WidgetStatusEnum.NEWS"
          widgetTitle="Aktualno"
          widgetIcon="newspaper"
          :showWidgetTitle="true"
        />
      </div>
      <div class="col-12 col-lg-6 co-xl-6">
        <Widget
          :widgetStatusEnum="WidgetStatusEnum.WEBCAM"
          widgetTitle="Radarska slika padavin (vir: ARSO)"
          widgetIcon="video"
          :showWidgetTitle="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Widget from "../../components/Widget/Widget.vue";

export default {
  name: "SodrazicaTemplate",
  components: {
    Widget,
  },
  props: {
    WidgetStatusEnum: Object,
    sliderData: Object,
    GetSodrazicaTitleDate: String,
    GetTableData: Object,
    GetChartOptions: Object,
    GetRecordsDayData: Object,
    cameraTitle: String,
  },
};
</script>

<style scoped>
@media (max-width: 991px) {
  .widget {
    margin-bottom: 15px;
  }
}

.row:before,
.row:after {
  display: none !important;
}
</style>