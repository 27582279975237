<template>
    <div class="card h-100">
      <div class="card-body p-0">
        <div class="card-title widget-title m-0" v-if="showWidgetTitle">
          <fa :icon="icon" id="style-icon" class="widget-header-icon"></fa>
          <span class="widget-header-title">{{widgetTitle}}</span>
        </div>
        <div class="widget-body">
            <div v-if="widgetStatusEnum === WidgetStatusEnum.STATION">
              <StationWidget :stationData="GetTableData" />
            </div>
            <div v-else-if="widgetStatusEnum === WidgetStatusEnum.WEBCAM">
              <WebcamWidget />
            </div>
            <div v-else-if="widgetStatusEnum === WidgetStatusEnum.CHART">
              <ChartWidget :chartOptions="GetChartOptions" :GetRecordsData="GetMaxStatsData" />
            </div>
            <div v-else-if="widgetStatusEnum === WidgetStatusEnum.NEWS">
              <NewsWidget />
            </div>
            <div v-else-if="widgetStatusEnum === WidgetStatusEnum.FORECAST">
              <ForecastWidget />
            </div>
            <div v-else-if="widgetStatusEnum === WidgetStatusEnum.SLIDESHOW">
              <SlideshowWidget :GetSliderImageData="GetSliderImageData"/>
            </div>
            <div v-else-if="widgetStatusEnum === WidgetStatusEnum.SPONSOR">
              <SateliteWidget />
            </div>
            <div v-else-if="widgetStatusEnum === WidgetStatusEnum.SODRAZICA">
              <SodrazicaWidget />
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import StationWidget from './Station/StationWidget.vue'
import WebcamWidget from './Radar/RadarWidget.vue'
import ChartWidget from './Chart/ChartWidget.vue'
import NewsWidget from './News/NewsWidget.vue'
import ForecastWidget from './Forecast/Forecast.vue'
import SlideshowWidget from './Slideshow/SlideshowWidget.vue'
import SateliteWidget from './Satelite/SateliteSponsorWidget.vue'
import SodrazicaWidget from './Satelite/SodrazicaWidget.vue';
import { WidgetStatusEnum } from '../../Enums/index'

export default {
  name: "Container",
  components: {
    StationWidget,
    WebcamWidget,
    ChartWidget,
    NewsWidget,
    ForecastWidget,
    SlideshowWidget,
    SateliteWidget,
    SodrazicaWidget
  },
  data() {
    return {
      title: this.widgetTitle,
      WidgetStatusEnum: WidgetStatusEnum,
      icon: this.widgetIcon,
    };
  },
  mounted() {
    this.params = this.$router.currentRoute.value.params;
    if(this.params.stationName == 'loski-potok') {
      if (this.widgetStatusEnum == WidgetStatusEnum.SPONSOR) {
            this.title = 'Spletna kamera Hrib - Loški Potok'
      }
    }
    else if(this.params.stationName == 'ribnica') {
      if (this.widgetStatusEnum == WidgetStatusEnum.SPONSOR) {
            this.title = 'Spletna kamera Prigorica'
      }
    }
    else if(this.params.stationName == 'sodrazica') {
      if (this.widgetStatusEnum == WidgetStatusEnum.SPONSOR) {
          this.title = 'Spletna kamera Sodražica';
      }
    }
  },
  props: {
    widgetStatusEnum: Number,
    widgetTitle: String,
    widgetIcon: String,
    GetChartOptions: Object,
    GetTableData: Object,
    GetMaxStatsData: Object,
    GetSliderImageData: Object,
    showWidgetTitle: Boolean
  }
};
</script>

<style scoped>
.widget {
  border-radius: 0 0 3px 3px;
  /*border: 1px solid #d3d3d3;*/
  border-top: 0;
}

.widget-title {
  background: -webkit-linear-gradient(top, #fafafa 0%, #e9e9e9 100%);
  height: 40px;
  line-height: 40px;
  font-size: 15px;
}

.widget-header-icon {
  display: inline-block;
  margin-top: -3px;
  margin-left: 13px;
  margin-right: -2px;
  font-size: 16px;
  color: #555;
  vertical-align: middle;
}

.widget-header-title {
  top: 0;
  position: relative;
  left: 10px;
  display: inline-block;
  margin-right: 3em;
  font-size: 15px;
  font-weight: 400;
  color: #555;
  line-height: 18px;
}

.widget-body {
    background: #FFF;
    border-top: none;
    border-radius: 5px;
}

@media (max-width: 1230px) {
  .widget-header-title {
    font-size: 12px;
  }
}
</style>