export default function translateToSlo(date) {
    let days = ["PON", "TOR", "SRE", "ČET", "PET", "SOB", "NED"];
    date.forEach(element => {
        if (element.valid_day._text === "Ponedeljek CET" ||element.valid_day._text === "Ponedeljek CEST") element.valid_day._text = days[0];
        if (element.valid_day._text === "Torek CET" || element.valid_day._text === "Torek CEST") element.valid_day._text = days[1];
        if (element.valid_day._text === "Sreda CET" || element.valid_day._text === "Sreda CEST") element.valid_day._text = days[2];
        if (element.valid_day._text === "Četrtek CET" || element.valid_day._text === "Četrtek CEST") element.valid_day._text = days[3];
        if (element.valid_day._text === "Petek CET" || element.valid_day._text === "Petek CEST") element.valid_day._text = days[4];
        if (element.valid_day._text === "Sobota CET" || element.valid_day._text === "Sobota CEST") element.valid_day._text = days[5];
        if (element.valid_day._text === "Nedelja CET" ||  element.valid_day._text === "Nedelja CEST") element.valid_day._text = days[6];
    });
    return date;
}