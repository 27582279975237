import translateToSlo from "./translateSlo.js";
import convert from "xml-js";
import ForecastTable from "./ForecastTable.js";
import moment from 'moment';
import router from '../../../router/index'

export default class Foreca {
    constructor(data, stationName) {
        this.data = data;
        this.stationName = stationName;
    }

    static FindStationName(stationName) {
        Foreca.stationName = stationName;
    }

    static Config() {
        let link = "https://postaje.vreme-podnebje.si/api/kocevjeForecast.php";
        return link;
    }
    static ConvertToJson(xml) {
        let result = convert.xml2js(xml, { compact: true, spaces: 4 });
        Foreca.data = result.data.metData;
    }

    static OrganizeData() {
        let data = Foreca.data;
        data = translateToSlo(data);
        this.ChangeDate(data);
        data = this.ChangeTemperature(data);
        data = this.ChangeSymbols(data);
        data = this.RemoveFirstDay(data);
        return data;
    }

    static ChangeSymbols(symbols) {
        symbols.forEach(element => {
            element.symbol = ForecastTable(element, Foreca.stationName);
            element.symbol = "https://developer.foreca.com/static/images/symbols/" + element.symbol + ".png";
        });
        return symbols;
    }

    static ChangeDate(data) {
        data.forEach(element => {
            var IsoDateTo = moment(element.valid_UTC._text,'DD/MM/YYYY').format('DD. MM');
            IsoDateTo = element.valid_day._text + ' ' + IsoDateTo;
            element.valid_day._text = IsoDateTo;
        })
    }

    static ChangeTemperature(data) {
        let path = router.currentRoute._value.params.stationName
        if(path === 'loski-potok') {
            data.forEach(x => { 
                x.tnsyn_degreesC._text = x.tnsyn_degreesC._text - 3;
                x.txsyn_degreesC._text = x.txsyn_degreesC._text -2;
            })
        }
        return data;
    }

    static RemoveFirstDay(data) {
        if (data.length == 6) {
            data.shift();
        }
        return data;
    }
}