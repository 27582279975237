<template>
  <div
    class="modal fade bannerformmodal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="bannerformmodal"
    aria-hidden="true"
    id="bannerformmodal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="myModalLabel">ARSO Radarska slika padavin</h4>
          </div>
          <div class="modal-body">
              <img class="img img-fluid" :src="url" />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-blue" data-bs-dismiss="modal">Zapri</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RadarModal",
  props: {
    url: String
  }
};
</script>