<template>
  <div>
    <div v-if="loading == true">Loading...</div>
    <div v-else>
      <div class="table-responsive">
        <table class="table text-center table-borderless mb-0">
          <thead>
            <tr :class="style.tdTag">
              <th scope="col" v-for="item in forecast" :key="item" :id="style.date">
                {{ item.valid_day._text }}.
              </th>
            </tr>
          </thead>
          <tbody>
            <tr :class="style.tdTag">
              <td v-for="item in forecast" :key="item" id="forecast">
                <img
                  :src="item.symbol"
                  class="img img-fluid m-2"
                  alt="..."
                  :id="style.symbol"
                />
              </td>
            </tr>
            <tr :class="style.tdTag">
              <td
                v-for="item in forecast"
                :key="item"
                :class="style.temp"
                :id="forecastBgColor.lowTemp"
              >
                {{ item.tnsyn_degreesC._text }} °C
              </td>
            </tr>
            <tr :class="style.tdTag">
              <td
                v-for="item in forecast"
                :key="item"
                :class="style.temp"
                :id="forecastBgColor.highTemp"
              >
                {{ item.txsyn_degreesC._text }} °C
              </td>
            </tr>
            <tr :class="style.tdTag">
              <td
                v-for="item in forecast"
                :key="item"
                class="variable"
                id="gust"
              >
                <img
                  :src="getWindDirection(item.ddff_icon._text)"
                  class="img img-fluid"
                  style="width: 20px; height: 20px"
                  alt="dir"
                /><span id="gustText">{{ item.ffmax_val_kmh._text }} km/h</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Foreca from "../../../functions/Forecast/Object/Foreca.js";
import router from "../../../router";

export default {
  name: "Test",
  components: {},
  data() {
    return {
      loading: true,
      errored: false,
      forecast: [],
      forecastBgColor: { lowTemp: "", highTemp: "" },
      style: {
        symbol: '',
        temp: 'smallerRows',
        tdTag: '',
        date: 'date'
      }
    };
  },
  methods: {
    getData() {
      axios(Foreca.Config())
        .then((response) => {
          Foreca.ConvertToJson(response.data);
          Foreca.FindStationName(this.$route.params.stationName);
          this.forecast = Foreca.OrganizeData();
        })
        .catch(function (error) {
          console.error(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getWindDirection(iconName) {
      let windDirectionUrl = `https://www.meteo.si/uploads/meteo/style/img/weather/${iconName}.png`;
      return windDirectionUrl;
    },
  },
  mounted() {
    let stationName = router.currentRoute._value.params.stationName;
    if (stationName == "loski-potok") {
      this.forecastBgColor.lowTemp = "low-temp1";
      this.forecastBgColor.highTemp = "high-temp1";
      this.style.symbol = "image-loski";
      this.style.temp = "fw-bolder variable-loski p-0";
      this.style.tdTag = "smaller";
    } else if (stationName == "ribnica") {
      this.forecastBgColor.lowTemp = "low-temp";
      this.forecastBgColor.highTemp = "high-temp";
      this.style.symbol = "image-ribnica";
      this.style.temp = "fw-bolder variable-ribnica";
    } else if (stationName == "sodrazica") {
       this.forecastBgColor.lowTemp = "low-temp_sodrazica";
       this.forecastBgColor.highTemp = "high-temp_sodrazica";
       this.style.symbol = "image-loski";
       this.style.temp = "fw-bolder variable-loski p-0";
       this.style.tdTag = "smaller";
       this.style.date = "date_sodrazica";
    }

    this.getData();
  },
};
</script>

<style scoped>
#gustText {
  font-size: 12px;
}

#image-ribnica {
  width: 60px;
  height: 60px;
}

#image-loski {
  width: 50px;
  height: 50px;
}

.variable-loski {
  font-size: 14px;
}

.smaller>td {
  padding: 0.15rem !important;
}

#low-temp {
  background-color: rgb(161, 213, 243);
  color: rgb(63, 63, 189);
}

#high-temp {
  background-color: rgb(235, 209, 140);
  color: rgb(253, 63, 5);
}

#low-temp1 {
  background-color: rgb(173, 230, 171);
  color: rgb(63, 63, 189);
}

#high-temp1 {
  background-color: rgb(240, 221, 221);
  color: rgb(253, 63, 5);
}

#low-temp_sodrazica {
  background-color: rgb(161, 213, 243);
  color: rgb(63, 63, 189);
}

#high-temp_sodrazica {
  background-color: rgb(240, 221, 221);
  color: rgb(253, 63, 5);
}

#gust {
  font-size: 16px;
}

#date {
  background-color: rgb(252, 248, 220);
}

#date_sodrazica {
  background-color: rgb(142, 255, 172);
}

#forecast {
  background-color: rgb(233, 246, 252);
}

@media (max-width: 1536px) {
  .variable-ribnica {
  font-size: 17px;
  }
  #date {
    font-size: 14px;
  }
  #forecast {
    font-size: 15px;
  }
  #gustText {
    font-size: 12px;
  }
}

@media (max-width: 1230px) {
  .variable-ribnica {
  font-size: 14px;
  }
  #date {
    font-size: 12px;
  }
  #forecast {
    font-size: 12px;
  }
  #gustText {
    font-size: 12px;
  }
}

@media (max-width: 576px) {
  #image-ribnica {
    width: 40px;
    height: 40px;
  }

  #image-loski {
    width: 40px;
    height: 40px;
  }

  #date_sodrazica {
    background-color: rgb(142, 255, 172);
    font-size: 12px;
  }
}
</style>
