const WidgetStatusEnum = Object.freeze({
    STATION: 1,
    CHART: 2,
    NEWS: 3,
    WEBCAM: 4,
    SLIDESHOW: 5,
    SPONSOR: 6,
    SODRAZICA: 7
  });
  
  export default WidgetStatusEnum;