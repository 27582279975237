<template>
  <div class="tablet">
    <a href="#" data-bs-toggle="modal" data-bs-target="#satelitesponsormodal">
      <img class="img img-fluid" :src="url" alt="cam" />
    </a>
    <SateliteSponsorModal :url="url"/>
  </div>
</template>

<script>
import router from "../../../router";
import SateliteSponsorModal from "./SateliteSponsorModal.vue";

export default {
  name: "SateliteWidget",
  components: {
    SateliteSponsorModal
  },
  data() {
    return {
      url: "",
      idModal: ""
    };
  },
  mounted() {
    let stationName = router.currentRoute._value.params.stationName;
    if (stationName == "loski-potok") {
      this.url = "https://postaje.vreme-podnebje.si/latest_web/loskiPotok.php";
    } else if (stationName == "ribnica") {
      this.url =
        "https://postaje.vreme-podnebje.si/latest_web/latest.php";
    } else if (stationName == "sodrazica") {
      this.url = "https://www.izver.si/vzivo.jpg";
      this.idModal = "";
    }
  },
};
</script>

<style scoped>
@media (max-width: 1200px) {
  .tablet {
    text-align: center;
  }
}
</style>